@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fonte-maior {
  font-size: 20px !important; }
  .fonte-maior div,
  .fonte-maior ul,
  .fonte-maior table {
    font-size: 20px !important; }

.alto-contraste {
  background-color: #fefefe;
  filter: invert(100%); }
  .alto-contraste * {
    background-color: inherit; }
  .alto-contraste img:not([src*=".svg"]),
  .alto-contraste video {
    filter: invert(100%); }

body {
  font-family: "Roboto", sans-serif !important; }

.fonte-12 {
  font-size: 12px; }

.fonte-14, .escolas .filtro h4, .escolas .filtro .limpar {
  font-size: 14px; }

.fonte-16 {
  font-size: 16px; }

.pref-menu {
  background-color: #9d9d9c;
  font-size: 12px;
  line-height: 12px; }
  .pref-menu li a {
    color: white; }

.links-menu {
  border-bottom: 1px solid #dee2e6; }
  @media (max-width: 576px) {
    .links-menu {
      border-bottom: 0 none !important; } }
  .links-menu .nav-link {
    border: 0 none !important; }
    .links-menu .nav-link:hover {
      border: 0 none;
      padding: 0.5rem 1rem; }

.busca-escolas {
  background: #f3f3f3 url(../../static/media/bg_busca.6a20c520.png);
  background-blend-mode: lighten;
  background-size: cover;
  color: #5c6f78;
  height: 490px; }
  .busca-escolas .conteudo {
    margin: 0 auto;
    z-index: 1;
    width: auto; }
    .busca-escolas .conteudo h2 {
      font-size: 30px;
      font-weight: bold; }
    .busca-escolas .conteudo .busca .form-control {
      font-size: 16px;
      font-style: italic;
      text-align: center; }
    .busca-escolas .conteudo .busca .rounded-pill {
      border-radius: 30px !important; }
    .busca-escolas .conteudo .busca .rounded-bottom-0 {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important; }
    .busca-escolas .conteudo .resultados.borda-on {
      border-color: #80bdff !important; }
    .busca-escolas .conteudo .resultados.borda-off {
      border-color: #ced4da !important; }
    .busca-escolas .conteudo .resultados .cursor-link {
      cursor: pointer; }

.sociedade-governo {
  background: #196cbd; }
  .sociedade-governo button {
    background: #afce68; }

.numeros {
  background: #00a6e2; }
  .numeros .rounded-circle {
    color: #196cbd;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    width: 200px; }
    .numeros .rounded-circle .valor {
      display: block;
      font-size: 40px;
      font-weight: bold; }
    .numeros .rounded-circle .texto {
      font-size: 16px; }

h2 {
  font-size: 35px;
  font-weight: bold !important;
  line-height: 40px; }
  h2.cor-azul {
    color: #196cbd; }

.menu-auxiliar {
  background: #196cbd; }
  .menu-auxiliar h3 {
    font-size: 24px;
    line-height: 28px; }

.resultados-autocomplete {
  max-height: 300px;
  overflow-y: auto;
  z-index: 5; }
  .resultados-autocomplete .cursor-padrao {
    cursor: default; }

@media (min-width: 992px) {
  .escolas {
    min-height: 860px; } }

.escolas .filtro {
  /*    @media (min-width: 992px) {
      position: absolute;
    }*/
  background: #00a6e2;
  position: absolute; }
  @media (max-width: 576px) {
    .escolas .filtro {
      left: 0; } }
  .escolas .filtro h4 {
    font-weight: bold; }
  .escolas .filtro .limpar {
    font-weight: bold;
    line-height: 16px; }

.escolas .overflow-auto .tabela-escolas {
  font-size: 0.8rem; }
  .escolas .overflow-auto .tabela-escolas tr.collapsing, .escolas .overflow-auto .tabela-escolas tr.collapse {
    background: #c4c4c4; }
  .escolas .overflow-auto .tabela-escolas .fa-chevron-down[aria-expanded="true"] {
    transform: rotate(180deg); }
  .escolas .overflow-auto .tabela-escolas tr[aria-expanded="true"] {
    background: #c4c4c4; }

@media (min-width: 992px) {
  .mapa-completo {
    padding-right: 0 !important;
    position: absolute !important;
    right: 0;
    width: 100% !important; } }

@media (max-width: 991px) {
  .mapa-completo {
    margin-top: 40px;
    margin-bottom: 40px; } }

.mapa-completo .mapa {
  background: #ffffff;
  border: 0 none;
  box-sizing: border-box; }
  .mapa-completo .mapa .leaflet-container {
    height: 860px; }
  @media (max-width: 991px) {
    .mapa-completo .mapa {
      margin-left: 10%;
      width: 80%;
      height: 10%; }
      .mapa-completo .mapa .leaflet-container {
        height: 500px; } }

.react-datepicker-wrapper {
  display: block !important; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    display: block !important; }

.area-rodape {
  background: #5a5a5a;
  color: #ffffff;
  font-size: 0.8rem; }

.endereco {
  font-size: 0.8rem; }

.estatisticas .nav {
  border-bottom-color: #196cbd;
  font-size: 12px; }
  .estatisticas .nav .nav-link {
    color: #42474a; }
    .estatisticas .nav .nav-link.active {
      border: 0 none;
      border-bottom: 8px solid #196cbd !important;
      font-weight: bold; }
    .estatisticas .nav .nav-link:hover {
      border: 0 none; }

.estatisticas .cor-azul {
  color: #196cbd; }

.estatisticas .cor-cinza {
  color: #5c6f78; }

.estatisticas-cabecalho h1 {
  font-size: 30px; }

.estatisticas-cabecalho .referencia {
  font-size: 12px; }

div.conheca-a-rede-subtitulo {
  font-weight: bold;
  font-size: 12px;
  color: #7cd8f9; }

div.header-acessibilidade {
  height: auto;
  background-color: #363636;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 20px; }
  div.header-acessibilidade li a {
    text-decoration: none;
    color: #fff; }
  div.header-acessibilidade li:hover {
    text-decoration: underline;
    cursor: pointer; }
  div.header-acessibilidade span.span-accesskey {
    margin-left: 5px;
    padding: 1px 6px;
    background-color: #196cbd; }
  div.header-acessibilidade svg {
    margin-left: 5px; }

#rodape {
  background: #363636;
  color: #fff; }

.tamanho-13 {
  font-size: 13px; }

.tamanho-14 {
  font-size: 14px; }

.bd-contact {
  border-left: 1px solid #FFFFFF;
  box-sizing: border-box; }

.footer-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold; }

.fonte-cor-branca, .fonte-cor-branca:hover {
  color: #fff; }

.figure-creative-commons {
  margin-top: 1.2rem; }

.btn-outline-primary {
  border: 2px solid #196CBD;
  color: #196CBD; }

.tabela-escolas-div {
  height: 800px; }

#filtro-collapse {
  z-index: 1 !important; }

